@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemon:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=K2D:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carter+One:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
  background: black,
  
}
:root {
  margin: 0;
  padding: 0;
  height: fit-content;
  background-image: url('/public/photonbg.png');
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
}
*,
::before,
::after {
  border-width: 0;
}
.bg-dimgray {
  background-color: dimgray;
}

.bg-dimgray-100:hover {
  background-color: #571818; /* Change this to the color you want on hover */
}


/* For traditional CSS */
.active-link {
  position: relative;
}

.active-link::after {
  content: '';
  display: block;
  height: 2px;
  background: white;
  position: absolute;
  bottom: -4px; /* Adjust as needed */
  left: 0;
  right: 0;
}
